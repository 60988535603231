import React, { useState } from "react";
import Img from "gatsby-image";
import clsx from "clsx";
import styles from "./pictureCard.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    width: "30%",
    maxHeight: 300,
  },
  text: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    zIndex: 3,
    fontSize: 24,
    color: "white",
  },
  media: {
    width: "100%",
    height: "100%",
  },
  actionArea: {
    width: "100%",
    height: "100%",
  },
});

export default function PictureCard(props) {
  const { fluid, duoToneFluid, text, textStyles, cardStyles, onClick } = props;
  const classes = useStyles();
  const [hovering, setIsHovering] = useState(false);

  return (
    <Card className={classes.root} raised={hovering} style={cardStyles}>
      <CardActionArea
        className={clsx(classes.actionArea, { [classes.hover]: hovering })}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onClick={onClick}
      >
        {!!text && <div className={clsx(classes.text, styles.text)} style={textStyles}>{text}</div>}
        <CardMedia className={classes.media}>
          <Img
            style={{
              overflow: "visible",
              display: !hovering || !duoToneFluid ? "block" : "none",
              width: "100%",
              height: "100%",
            }}
            fluid={fluid}
            imgStyle={{ objectFit: "fill" }}
          />
          {!!duoToneFluid && (
            <Img
              style={{ overflow: "visible", display: hovering ? "block" : "none", width: "100%", height: "100%" }}
              fluid={duoToneFluid}
              imgStyle={{ objectFit: "fill" }}
            />
          )}
        </CardMedia>
      </CardActionArea>
    </Card>
  );
}
